import {
  Box,
  BoxProps,
  Button,
  Center,
  CloseButton,
  Flex,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Switch,
  useColorModeValue
} from '@chakra-ui/react'
import { logOut, useAuthenticationStore, useLessonPathModeStore, User } from '@heytutor/common'
import { Role } from '@heytutor/core'
import { IconType } from 'react-icons'
import { FiBook, FiTag } from 'react-icons/fi'
import { LuSchool, LuTicketSlash } from 'react-icons/lu'
import { PiPath, PiSignOut } from 'react-icons/pi'
import { getIsUsingNewLessonPathMode } from 'utils'
import { removePagination } from 'utils/Pagination'
import routes from 'utils/routes'
import NavItem from './nav-item'

interface LinkItemProps {
  name: string
  icon: IconType
  href: string
  action?: Action
  roles: Array<number>
}

enum Action {
  Logout
}

const LinkItems: Array<LinkItemProps> = [
  {
    name: 'Lessons',
    icon: FiBook,
    href: routes.lessons,
    roles: [Role.SCHOOL_ADMIN, Role.TUTOR, Role.ADMIN, Role.CONTENT_ADMIN]
  },
  {
    name: 'Lesson Paths',
    icon: PiPath,
    href: routes.lessonPaths,
    roles: [Role.ADMIN, Role.CONTENT_ADMIN]
  },
  ...(getIsUsingNewLessonPathMode()
    ? [
        {
          name: 'Districts',
          icon: LuSchool,
          href: routes.districts,
          roles: [Role.ADMIN, Role.CONTENT_ADMIN]
        }
      ]
    : []),
  {
    name: 'Metatags',
    icon: FiTag,
    href: routes.metatags,
    roles: [Role.ADMIN, Role.CONTENT_ADMIN]
  },
  {
    name: 'Tickets',
    icon: LuTicketSlash,
    href: routes.tickets,
    roles: [Role.ADMIN, Role.CONTENT_ADMIN]
  },
  {
    name: 'Sign out',
    icon: PiSignOut,
    href: '#',
    action: Action.Logout,
    roles: [Role.SCHOOL_STUDENT, Role.TUTOR, Role.ADMIN, Role.CONTENT_ADMIN]
  }
]

interface SidebarProps extends BoxProps {
  onClose: () => void
  lessonHandler: any
  lessonPathHandler: any
  metatagHandler: any
}

const SidebarContent = ({ onClose, lessonHandler, lessonPathHandler, metatagHandler, ...rest }: SidebarProps) => {
  const { usingNewLessonPathMode, setUsingNewLessonPathMode } = useLessonPathModeStore((state) => state)
  const user: User = useAuthenticationStore((state) => state.user)

  const onClickByAction = (action?: Action) => {
    if (action == Action.Logout) {
      logOut()
    }

    return true
  }

  const checkChange = (name) => {
    if (name != 'Lessons') {
      removePagination(routes.lessons)
    }

    if (name != 'Lesson Paths') {
      removePagination(routes.lessonPaths)
    }

    if (name != 'Metatags') {
      removePagination(routes.metatags)
    }

    return true
  }

  const lessonPathModeChange = () => {
    setUsingNewLessonPathMode(!usingNewLessonPathMode)
    location.reload()
  }

  return (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      h="full"
      {...rest}
      zIndex="1000"
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Flex alignItems="center" display={{ base: 'flex', md: 'flex' }}>
          <Image src="/images/logo-new.svg" alt="HeyTutor Logo" width={50} height={50} />
          <Heading as="h6" size="md" fontWeight={700} lineHeight="normal" ml={2}>
            HeyTutor
          </Heading>
        </Flex>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>

      {(user?.role == Role.ADMIN || user?.role == Role.CONTENT_ADMIN || user == null) && (
        <Box mx="8" mt="8" mb="4">
          <Menu closeOnSelect={true}>
            <Skeleton isLoaded={user != null}>
              <MenuButton as={Button} colorScheme="purple">
                Create
              </MenuButton>
            </Skeleton>
            <MenuList minWidth="190px">
              <MenuItem onClick={() => lessonHandler.open()}>Create Lesson</MenuItem>
              <MenuItem onClick={() => lessonPathHandler.open()}>Create Lesson Path</MenuItem>
              <MenuItem onClick={() => metatagHandler.open()}>Create Metatag</MenuItem>
            </MenuList>
          </Menu>
        </Box>
      )}

      {(user?.role == Role.ADMIN || user?.role == Role.CONTENT_ADMIN || user == null) && (
        <Center gap={'10px'} mb="4">
          <Box>Switch to 24/25 mode</Box>
          <Switch
            id="lesson-path-mode-change"
            onChange={lessonPathModeChange}
            isChecked={usingNewLessonPathMode}
            colorScheme="purple"
          />
        </Center>
      )}

      {user != null
        ? LinkItems.filter((x) => x.roles.includes(user?.role)).map((link) => (
            <NavItem
              key={link.name}
              icon={link.icon}
              href={link.href}
              onClick={() => {
                onClickByAction(link.action)
                checkChange(link.name)
              }}
            >
              {link.name}
            </NavItem>
          ))
        : LinkItems.map((link, index) => (
            <Skeleton key={`linkSkeleton${index}`}>
              <NavItem
                key={link.name}
                icon={link.icon}
                href={link.href}
                onClick={() => {
                  onClickByAction(link.action)
                  checkChange(link.name)
                }}
              >
                {link.name}
              </NavItem>
            </Skeleton>
          ))}
    </Box>
  )
}

export default SidebarContent
